import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // Import Axios instance
import { FaEdit, FaTrash, FaPlus } from "react-icons/fa"; // Removed FaSearch import
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ManageNews.css"; // This file contains your CSS

const CourseNews = () => {
  const [newsList, setNewsList] = useState([]);
  // Removed searchTerm state since search is no longer used
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  // Rename "description" to "content"
  const [formData, setFormData] = useState({ id: null, title: "", content: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Fetch news data with pagination (removed keyword search)
  const fetchNews = async () => {
    try {
      const response = await axiosInstance.get("/api/course-news", {
        params: {
          view: "pagewise",
          page: currentPage - 1,
          size: pageSize === "all" ? undefined : pageSize,
        },
      });
      setNewsList(response.data.content || response.data);
      setTotalPages(response.data.totalPages || 1);
    } catch (error) {
      console.error("Error fetching news:", error.response?.data || error.message);
      toast.error("Failed to load news.");
    }
  };

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddEditNews = async () => {
    if (!formData.title || !formData.content) {
      toast.error("Title and content are required.");
      return;
    }
    try {
      if (isEditing) {
        await axiosInstance.put(`/api/course-news/${formData.id}`, formData);
        toast.success("News updated successfully!");
      } else {
        await axiosInstance.post("/api/course-news", formData);
        toast.success("News added successfully!");
      }
      setFormData({ id: null, title: "", content: "" });
      setIsEditing(false);
      setShowForm(false);
      fetchNews();
    } catch (error) {
      console.error("Error saving news:", error.response?.data || error.message);
      toast.error("Failed to save news.");
    }
  };

  const handleDeleteNews = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this news?")) {
        await axiosInstance.delete(`/api/course-news/${id}`);
        toast.success("News deleted successfully!");
        fetchNews();
      }
    } catch (error) {
      console.error("Error deleting news:", error.response?.data || error.message);
      toast.error("Failed to delete news.");
    }
  };

  const handleAddClick = () => {
    setFormData({ id: null, title: "", content: "" });
    setIsEditing(false);
    setShowForm(true);
  };

  return (
    <div className="course-container">
      <ToastContainer />
      <h1 className="course-title">Manage Course News</h1>

      {/* Actions */}
      <div className="search-bar">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(e.target.value);
            setCurrentPage(1);
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value="all">All</option>
        </select>
        <button onClick={handleAddClick} className="add-button">
          <FaPlus /> Add News
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="title"
            placeholder="Title"
            value={formData.title}
            onChange={handleInputChange}
          />
          <textarea
            name="content"
            placeholder="Content"
            value={formData.content}
            onChange={handleInputChange}
          ></textarea>
          <div className="form-buttons">
            <button onClick={handleAddEditNews} className="btn action-btn">
              {isEditing ? "Update News" : "Add News"}
            </button>
            <button
              onClick={() => {
                setShowForm(false);
                setFormData({ id: null, title: "", content: "" });
                setIsEditing(false);
              }}
              className="btn action-btn cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* News Cards */}
      <div className="course-list">
        {newsList.length > 0 ? (
          newsList.map((news) => (
            <div key={news.id} className="course-card">
              <h3>{news.title}</h3>
              <p className="course-description">{news.content}</p>
              <div className="action-buttons">
                <button
                  onClick={() => {
                    setFormData(news);
                    setIsEditing(true);
                    setShowForm(true);
                  }}
                  className="btn action-btn edit"
                >
                  <FaEdit /> Edit
                </button>
                <button
                  onClick={() => handleDeleteNews(news.id)}
                  className="btn action-btn delete"
                >
                  <FaTrash /> Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center" }}>No news found.</p>
        )}
      </div>

      {/* Pagination */}
      {pageSize !== "all" && (
        <div className="pagination">
          <button
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default CourseNews;
