import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          At <strong>Encoders Software Pvt. Ltd.</strong>, we prioritize your privacy and are fully committed to protecting your personal information. This Privacy Policy outlines how we collect, use, store, and safeguard your data when you interact with our website, products, and services.
        </p>

        <h2>1. Information We Collect</h2>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, phone number, company name, and billing details.</li>
          <li><strong>Non-Personal Information:</strong> IP address, browser type, device information, and website usage data.</li>
          <li><strong>Cookies and Tracking Data:</strong> We use cookies and similar tracking technologies to enhance your browsing experience.</li>
        </ul>

        <h2>2. Login via Third-Party Services</h2>
        <p>
          We offer login authentication via third-party services, including:
        </p>
        <ul>
          <li><strong>Facebook Login:</strong> Users can sign in using their Facebook credentials.</li>
          <li><strong>Google Login:</strong> Users can log in with their Google accounts.</li>
          <li><strong>GitHub Login:</strong> Authentication is available via GitHub for developers.</li>
        </ul>
        <p>
          When logging in with these third-party services, we only access essential details required for authentication and do not store additional personal data without consent.
        </p>

        <h2>3. How We Use Your Information</h2>
        <ul>
          <li>Provide and improve our products and services.</li>
          <li>Respond to customer inquiries and support requests.</li>
          <li>Send updates, newsletters, and promotional offers (with your consent).</li>
          <li>Analyze website traffic and improve website performance.</li>
          <li>Ensure compliance with legal obligations and prevent fraudulent activities.</li>
        </ul>

        <h2>4. Payment Gateway Security</h2>
        <p>
          We use secure third-party payment gateways to process transactions. These gateways comply with industry security standards, such as:
        </p>
        <ul>
          <li><strong>PCI DSS Compliance:</strong> Payment gateways adhere to PCI DSS security standards to protect sensitive payment information.</li>
          <li><strong>Encryption:</strong> Transactions are encrypted using SSL/TLS protocols.</li>
          <li><strong>Secure Tokenization:</strong> Payment information is not stored directly on our servers but processed securely.</li>
        </ul>
        <p>
          Users are advised to review the payment providers’ privacy policies before making transactions.
        </p>

        <h2>5. Data Deletion Policy</h2>
        <p>
          Users have the right to request the deletion of their personal data. To delete your account and associated information, please follow these steps:
        </p>
        <ul>
          <li><strong>Submit a Request:</strong> Contact us via email at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a> with a data deletion request.</li>
          <li><strong>Verification:</strong> We may ask you to verify your identity before processing the deletion request.</li>
          <li><strong>Processing Time:</strong> Data deletion requests are typically processed within 7-14 business days.</li>
          <li><strong>Exceptions:</strong> Some data may be retained for legal or security purposes.</li>
        </ul>

        <h2>6. Data Security</h2>
        <p>
          We implement robust security measures, including encryption, firewalls, and secure access controls, to protect your personal data from unauthorized access, loss, misuse, or alteration. However, no method of data transmission or storage is entirely secure, and we cannot guarantee absolute security.
        </p>

        <h2>7. Cookies and Tracking Technologies</h2>
        <ul>
          <li>Personalize your browsing experience.</li>
          <li>Analyze website performance and traffic patterns.</li>
          <li>Store your preferences for future visits.</li>
        </ul>
        <p>You can control or disable cookies through your browser settings. However, disabling cookies may affect your user experience.</p>

        <h2>8. Your Rights and Choices</h2>
        <ul>
          <li><strong>Access and Update:</strong> You can access and update your personal information at any time.</li>
          <li><strong>Opt-Out:</strong> You can opt out of receiving marketing emails and communications.</li>
          <li><strong>Data Deletion:</strong> You can request the deletion of your personal data.</li>
          <li><strong>Data Portability:</strong> You can request a copy of your data in a structured format.</li>
        </ul>

        <h2>9. Third-Party Data Sharing</h2>
        <p>
          We may share information with trusted third-party service providers who assist in operations, such as cloud hosting, analytics, and customer support.
        </p>

        <h2>10. International Data Transfers</h2>
        <p>
          Your information may be transferred and processed outside your country of residence. We ensure appropriate safeguards are in place to protect your data during international transfers.
        </p>

        <h2>11. Compliance with Legal Obligations</h2>
        <p>
          We comply with all applicable data protection laws, including the GDPR, CCPA, and Indian IT Act. If required by law enforcement, we may disclose personal data in compliance with legal requirements.
        </p>

        <h2>12. Contact Us</h2>
        <p>
          If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us:
        </p>
        <p>
          📧 <strong>Email:</strong> <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a><br />
          📞 <strong>Phone:</strong> <a href="tel:+917258803502">+91 7258803502</a><br />
        </p>
        <p>
          📍 <strong>Encoders Software Pvt. Ltd.</strong><br />
          Oak Forest, Flat No. 8F,<br />
          Pundag Road, Ranchi,<br />
          Jharkhand, 834002, India.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
