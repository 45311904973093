import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {
  FaUser,
  FaSignOutAlt,
  FaTachometerAlt,
  FaFileAlt,
  FaBars,
  FaArrowLeft,
} from "react-icons/fa";
import ManageNews from "./ManageNews";
import ManageFAQs from "./ManageFAQs";
import JobServices from "./JobServices";
import UserProfile from "./UserProfile";
import UserManage from "./UserManage";
import ApplicationManagement from "./ApplicationManagement";
import ProjectServices from "./ProjectServices";
import ManageServices from "./ManageServices";
import Categories from "./Categories";
import ContactDetails from "./ContactDetails";
import AdminDashboard from "./AdminDashboard";
import PayslipForm from "./PayslipForm";
import CourseManagement from "./CourseManagement";
import DiscountGenerator from "./DiscountGenerator";
import Blogs from "./Blogs";
import ManageTeam from "./ManageTeam";
import ViewApplications from "./ViewApplications";
import ManageTestimonials from "./ManageTestimonials";
import PaymentDetails from "./PaymentDetails";
import CourseNews from "./CourseNews";
// New imports for Photo Management


import "./HomePage.css";
import decrypt from "../../include/decrypt";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [userData, setUserData] = useState({ email: "", role: "Guest" });
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeMenu, setActiveMenu] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [menuLoading, setMenuLoading] = useState(false);

  useEffect(() => {
    const authenticateUser = () => {
      const queryParams = new URLSearchParams(window.location.search);
      const accessToken = decrypt(queryParams.get("accessToken"));
      const email = decrypt(queryParams.get("email"));
      const role = decrypt(queryParams.get("role"));
      const refreshToken = decrypt(queryParams.get("refreshToken"));

      if (accessToken && email && role) {
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("role", role);
        localStorage.setItem("email", email);
        setUserData({ email, role });
        setIsAuthenticated(true);
        setIsLoading(false);

        if (role === "ADMIN") {
          navigate("/HomePage");
        } else {
          const redirectTo = location.state?.redirectTo || "/";
          const job = location.state?.job;
          if (redirectTo === "/apply" && job) {
            navigate(redirectTo, { state: { job } });
          } else {
            navigate(redirectTo);
          }
        }
      } else {
        const sessionEmail = localStorage.getItem("email");
        const sessionRole = localStorage.getItem("role");
        const sessionToken = localStorage.getItem("accessToken");
        if (sessionEmail && sessionToken && sessionRole) {
          setUserData({ email: sessionEmail, role: sessionRole });
          setIsAuthenticated(true);
          setIsLoading(false);
        } else {
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      }
    };
    authenticateUser();
  }, [navigate, location]);

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    Cookies.remove("accessEmailCookie");
    Cookies.remove("accessRoleCookie");
    navigate("/LoginSignup");
  };

  const handleMenuClick = (menu) => {
    setMenuLoading(true);
    setActiveMenu(menu);
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Simulate loading delay; replace with your async fetch if needed.
    setTimeout(() => {
      setMenuLoading(false);
    }, 700);
  };

  const handleBack = () => {
    navigate("/");
  };

  const renderMenuOptions = () => {
    if (userData.role === "ADMIN") {
      return (
        <ul className="menu-options">
          <li
            onClick={() => handleMenuClick("Dashboard")}
            className={activeMenu === "Dashboard" ? "active" : ""}
          >
            <FaTachometerAlt /> Dashboard
          </li>
          <li
            onClick={() => handleMenuClick("ManageNews")}
            className={activeMenu === "ManageNews" ? "active" : ""}
          >
            <FaFileAlt /> Manage News
          </li>
          <li
            onClick={() => handleMenuClick("ManageTestimonials")}
            className={activeMenu === "ManageTestimonials" ? "active" : ""}
          >
            <FaFileAlt /> Manage Testimonials
          </li>
          <li
            onClick={() => handleMenuClick("ManageFAQs")}
            className={activeMenu === "ManageFAQs" ? "active" : ""}
          >
            <FaFileAlt /> Manage FAQs
          </li>
          <li
            onClick={() => handleMenuClick("ManageServices")}
            className={activeMenu === "ManageServices" ? "active" : ""}
          >
            <FaFileAlt /> Manage Services
          </li>
          <li
            onClick={() => handleMenuClick("ProjectServices")}
            className={activeMenu === "ProjectServices" ? "active" : ""}
          >
            <FaFileAlt /> Project Services
          </li>
          <li
            onClick={() => handleMenuClick("JobServices")}
            className={activeMenu === "JobServices" ? "active" : ""}
          >
            <FaFileAlt /> Job Services
          </li>
          <li
            onClick={() => handleMenuClick("ApplicationManagement")}
            className={
              activeMenu === "ApplicationManagement" ? "active" : ""
            }
          >
            <FaFileAlt /> Application Management
          </li>
          <li
            onClick={() => handleMenuClick("ManageTeam")}
            className={activeMenu === "ManageTeam" ? "active" : ""}
          >
            <FaFileAlt /> Manage Team
          </li>
          <li
            onClick={() => handleMenuClick("UserManage")}
            className={activeMenu === "UserManage" ? "active" : ""}
          >
            <FaFileAlt /> Manage User
          </li>
          <li
            onClick={() => handleMenuClick("ViewApplications")}
            className={activeMenu === "ViewApplications" ? "active" : ""}
          >
            <FaFileAlt /> View Applications
          </li>
          <li
            onClick={() => handleMenuClick("Categories")}
            className={activeMenu === "Categories" ? "active" : ""}
          >
            <FaFileAlt /> Blog Categories Management
          </li>
          <li
            onClick={() => handleMenuClick("Blogs")}
            className={activeMenu === "Blogs" ? "active" : ""}
          >
            <FaFileAlt /> Blog Management
          </li>
          <li
            onClick={() => handleMenuClick("ContactDetails")}
            className={activeMenu === "ContactDetails" ? "active" : ""}
          >
            <FaFileAlt /> Message By Customers
          </li>
          <li
            onClick={() => handleMenuClick("AdminDashboard")}
            className={activeMenu === "AdminDashboard" ? "active" : ""}
          >
            <FaFileAlt /> Admin Dashboard
          </li>
          <li
            onClick={() => handleMenuClick("PayslipForm")}
            className={activeMenu === "PayslipForm" ? "active" : ""}
          >
            <FaFileAlt /> Payslip Generation
          </li>
          <li
            onClick={() => handleMenuClick("CourseManagement")}
            className={activeMenu === "CourseManagement" ? "active" : ""}
          >
            <FaFileAlt /> Course Management
          </li>
          <li
            onClick={() => handleMenuClick("DiscountGenerator")}
            className={activeMenu === "DiscountGenerator" ? "active" : ""}
          >
            <FaFileAlt /> Discount Code Generator
          </li>
          <li
            onClick={() => handleMenuClick("PaymentDetails")}
            className={activeMenu === "PaymentDetails" ? "active" : ""}
          >
            <FaFileAlt /> Payment Details
          </li>
          <li
            onClick={() => handleMenuClick("CourseNews")}
            className={activeMenu === "CourseNews" ? "active" : ""}
          >
            <FaFileAlt /> Course News
          </li>
          
        </ul>
      );
    } else if (userData.role === "USER") {
      return (
        <ul className="menu-options">
          <li
            onClick={() => handleMenuClick("Dashboard")}
            className={activeMenu === "Dashboard" ? "active" : ""}
          >
            <FaTachometerAlt /> Dashboard
          </li>
          <li
            onClick={() => handleMenuClick("UserProfile")}
            className={activeMenu === "UserProfile" ? "active" : ""}
          >
            <FaFileAlt /> Update Profile
          </li>
          <li
            onClick={() => handleMenuClick("ViewApplications")}
            className={activeMenu === "ViewApplications" ? "active" : ""}
          >
            <FaFileAlt /> Job & Offer Letter Status
          </li>
        </ul>
      );
    } else {
      // If no valid role, logout
      handleLogout();
      return null;
    }
  };

  const renderMainContent = () => {
    if (menuLoading) {
      return (
        <div className="menu-loader">
          <div className="spinner"></div>
        </div>
      );
    }
    switch (activeMenu) {
      case "Dashboard":
        return <div>Welcome to the {userData.role} Dashboard!</div>;
      case "ManageNews":
        return <ManageNews />;
      case "ManageTestimonials":
        return <ManageTestimonials />;
      case "ManageFAQs":
        return <ManageFAQs />;
      case "ManageServices":
        return <ManageServices />;
      case "ProjectServices":
        return <ProjectServices />;
      case "JobServices":
        return <JobServices />;
      case "ManageTeam":
        return <ManageTeam />;
      case "UserManage":
        return <UserManage />;
      case "ApplicationManagement":
        return <ApplicationManagement />;
      case "Categories":
        return <Categories />;
      case "Blogs":
        return <Blogs />;
      case "ContactDetails":
        return <ContactDetails />;
      case "AdminDashboard":
        return <AdminDashboard />;
      case "PayslipForm":
        return <PayslipForm />;
      case "CourseManagement":
        return <CourseManagement />;
      case "DiscountGenerator":
        return <DiscountGenerator />;
      case "PaymentDetails":
        return <PaymentDetails />;
      case "CourseNews":
        return <CourseNews />;
      case "ViewApplications":
        return <ViewApplications />;
      case "UserProfile":
        return (
          <UserProfile
            loggedInEmail={userData.email}
            accessToken={
              localStorage.getItem("accessToken") ||
              sessionStorage.getItem("token")
            }
          />
        );
      /** New Photo Management Parent */
     
      default:
        return <div>Select a menu option to get started.</div>;
    }
  };

  if (isLoading) {
    return (
      <div className="loader-container">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="homepage-container">
      {/* Top Bar */}
      <div className="top-bar">
        <button className="back-btn" onClick={handleBack}>
          <FaArrowLeft /> Back
        </button>
        <div className="user-info">
          <FaUser /> {userData.email} | Role: {userData.role}
        </div>
        <button className="logout-btn" onClick={handleLogout}>
          <FaSignOutAlt /> Logout
        </button>
      </div>

      {/* Layout: Sidebar + Main Content */}
      <div className="layout">
        {/* Sidebar */}
        <div className={`sidebar ${sidebarOpen ? "open" : "collapsed"}`}>
          <div className="sidebar-header">
            <h3>Admin Menu</h3>
            <button
              className="toggle-btn"
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FaBars />
            </button>
          </div>
          {renderMenuOptions()}
        </div>

        {/* Main Content */}
        <div className="main-content">{renderMainContent()}</div>
      </div>
    </div>
  );
};

export default HomePage;
