import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axiosInstance from "./axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./TrainingCourse.css";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTimes,
  faChevronDown,
  faChevronUp,
  faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { motion, AnimatePresence } from "framer-motion";
import { debounce } from "lodash";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import NewsScroller from "./NewsScroller";

// A reusable Button component
const Button = ({ children, className, ...props }) => (
  <button className={`action-btn ${className}`} {...props}>
    {children}
  </button>
);

// Loader spinner
const Loader = () => <div className="loader"></div>;

export default function TrainingCourse({ direction = "down", speed = 1 }) {
  const { course_id } = useParams();
  const [courses, setCourses] = useState([]);
  const [images, setImages] = useState({});
  const [loadingImages, setLoadingImages] = useState({});

  // For expand/collapse in the card
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // For the modal
  const [selectedCourse, setSelectedCourse] = useState(null);

  // Main page loading
  const [loading, setLoading] = useState(true);

  // Pagination & search
  const [page] = useState(0); // or implement next/prev
  const [size] = useState(6);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalPages, setTotalPages] = useState(1);

  // Text zoom in the modal
  const [textZoomLevel, setTextZoomLevel] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fetchCourses();
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    // Scroll to top or course title on load
    setTimeout(() => {
      const courseTitle = document.querySelector(".course-title");
      if (courseTitle) {
        courseTitle.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 900);
  }, [location]);

  // Debounced search
  const debouncedSearch = useCallback(
    debounce((query) => setSearchQuery(query), 500),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const fetchCourses = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/courses/search?page=${page}&size=${size}&title=${searchQuery}`
      );
      if (response.data && response.data.content) {
        setCourses(response.data.content);
        setTotalPages(response.data.totalPages);

        // For each course, fetch the image
        response.data.content.forEach((course) => {
          setLoadingImages((prev) => ({ ...prev, [course.id]: true }));
          fetchCourseImage(course.id);
        });
      } else {
        setCourses([]);
        setTotalPages(1);
      }
    } catch (error) {
      toast.error("Failed to fetch courses. Please try again.");
    }
    setLoading(false);
  };

  const fetchCourseImage = async (id) => {
    try {
      const response = await axiosInstance.get(`/api/courses/${id}/image`, {
        responseType: "blob",
      });
      const imageUrl = URL.createObjectURL(response.data);
      setImages((prev) => ({ ...prev, [id]: imageUrl }));
    } catch (error) {
      setImages((prev) => ({ ...prev, [id]: null }));
    } finally {
      setLoadingImages((prev) => ({ ...prev, [id]: false }));
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Utility to show short excerpt in the card
  const getExcerpt = (text, wordLimit = 50) => {
    const words = text.split(/\s+/);
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(" ") + " ...";
  };

  const openPopup = (course) => {
    setSelectedCourse(course);
    setTextZoomLevel(1); // reset text zoom to 1
    navigate(`/training-course/${course.id}`);
  };

  const closePopup = () => {
    setSelectedCourse(null);
    navigate(`/training-course`);
  };

  // If user refreshes at /training-course/:id, open popup if data is ready
  useEffect(() => {
    if (!course_id || !courses.length) return;
    const found = courses.find((c) => c.id.toString() === course_id);
    if (found) openPopup(found);
    // eslint-disable-next-line
  }, [courses, course_id]);

  // Apply button
  const applyCourse = (course) => {
    const isLoggedIn = localStorage.getItem("accessToken");
    if (isLoggedIn) {
      navigate("/pay", { state: { course } });
    } else {
      // if not logged in, redirect to login
      navigate("/LoginSignup", { state: { redirectTo: "/pay", course } });
    }
  };

  // Sharing
  const shareOnFacebook = (course) => {
    const shareUrl = `${window.location.origin}/training-course/${course.id}`;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(facebookUrl, "_blank", "noopener,noreferrer");
  };

  const shareOnWhatsApp = (course) => {
    const shareUrl = `${window.location.origin}/training-course/${course.id}`;
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  const shareViaMail = (course) => {
    const shareUrl = `${window.location.origin}/training-course/${course.id}`;
    const mailUrl = `mailto:?subject=${encodeURIComponent(
      course.title
    )}&body=${encodeURIComponent("Check out this course: " + shareUrl)}`;
    window.location.href = mailUrl;
  };

  // Text zoom
  const zoomInText = () => {
    setTextZoomLevel((prev) => Math.min(prev + 0.1, 2));
  };

  const zoomOutText = () => {
    setTextZoomLevel((prev) => Math.max(prev - 0.1, 1));
  };

  return (
    <div>
      <Navbar />

      {/* Full-screen loader when fetching data */}
      {loading && (
        <div className="page-loader-container">
          <Loader />
        </div>
      )}

      {/* Render the page if not loading */}
      {!loading && (
        <>
          <div className="course-container">
            <h1 className="course-title">Explore Our Training Courses</h1>

            <div className="top-container">
              <div className="search-bar">
                <FontAwesomeIcon icon={faSearch} className="icon" />
                <input
                  type="text"
                  placeholder="Search Courses..."
                  onChange={handleSearchChange}
                />
              </div>

              <NewsScroller direction="down" speed={1} />
            </div>

            <div className="course-list">
              {courses.length > 0 ? (
                courses.map((course) => (
                  <motion.div
                    key={course.id}
                    className="course-card"
                    whileHover={{ scale: 1.02 }}
                    transition={{ type: "spring", stiffness: 200 }}
                    onClick={() => openPopup(course)}
                  >
                    <div className="image-container">
                      {/* Show loader while image is fetching */}
                      {loadingImages[course.id] && (
                        <div className="image-loader">
                          <Loader />
                        </div>
                      )}
                      {/* If loaded and has image, show it */}
                      {!loadingImages[course.id] && images[course.id] && (
                        <img
                          src={images[course.id]}
                          alt={course.title}
                          className="course-image"
                        />
                      )}
                      {/* No image case */}
                      {!loadingImages[course.id] && !images[course.id] && (
                        <div className="no-image">No Image Available</div>
                      )}
                    </div>

                    <h3>{course.title}</h3>
                    <div className="course-description">
                      <ReactQuill
                        value={
                          expandedDescriptions[course.id]
                            ? course.description
                            : getExcerpt(course.description, 50)
                        }
                        readOnly
                        theme="bubble"
                      />
                      <span
                        className="read-toggle"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDescription(course.id);
                        }}
                      >
                        {expandedDescriptions[course.id] ? (
                          <>
                            <FontAwesomeIcon icon={faChevronUp} /> Read Less
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faChevronDown} /> Read More
                          </>
                        )}
                      </span>
                    </div>

                    <Button
                      className="apply-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        applyCourse(course);
                      }}
                    >
                      Apply
                    </Button>
                  </motion.div>
                ))
              ) : (
                <p className="no-courses">No courses available.</p>
              )}
            </div>
          </div>

          <Footer />
          <ToastContainer position="top-right" autoClose={3000} />

          {/* Modal ~ no image, just full content + share + apply */}
          <AnimatePresence>
            {selectedCourse && (
              <motion.div
                className="modal-overlay"
                onClick={closePopup}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.div
                  className="modal-content"
                  onClick={(e) => e.stopPropagation()}
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  exit={{ scale: 0.8 }}
                >
                  <button className="close-btn" onClick={closePopup}>
                    <FontAwesomeIcon icon={faTimes} />
                  </button>

                  <h2 className="modal-title">{selectedCourse.title}</h2>

                  {/* Full description in the modal (No excerpt) */}
                  <div
                    className="text-zoom-container"
                    style={{
                      transform: `scale(${textZoomLevel})`,
                      transformOrigin: "top left",
                    }}
                  >
                    <ReactQuill
                      value={selectedCourse.description}
                      readOnly
                      theme="bubble"
                    />
                  </div>

                  <div className="modal-actions">
                    <Button
                      className="apply-btn"
                      onClick={() => applyCourse(selectedCourse)}
                    >
                      Apply Now
                    </Button>

                    <div className="modal-actions">
                      <div className="share-buttons" style={{ gap: "20px" }}>
                        <Button
                          className="share-btn"
                          onClick={() => shareOnFacebook(selectedCourse)}
                        >
                          <FontAwesomeIcon icon={faFacebook} />
                        </Button>
                        <Button
                          className="share-btn"
                          onClick={() => shareOnWhatsApp(selectedCourse)}
                        >
                          <FontAwesomeIcon icon={faWhatsapp} />
                        </Button>
                        <Button
                          className="share-btn"
                          onClick={() => shareViaMail(selectedCourse)}
                        >
                          <FontAwesomeIcon icon={faShareAlt} />
                        </Button>
                      </div>

                      <div className="zoom-controls">
                        <button className="zoom-in" onClick={zoomInText}>
                          A+
                        </button>
                        <button className="zoom-out" onClick={zoomOutText}>
                          A-
                        </button>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </div>
  );
}
