import React, { useEffect, useState } from "react";
import axiosInstance from "./axiosInstance";
import { Table, Input, Button, Select, message, Checkbox, Dropdown, Menu } from "antd";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import debounce from "lodash.debounce";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import "./PaymentDetails.css";

const { Option } = Select;

const PaymentDetails = () => {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedColumns, setSelectedColumns] = useState(["srNo", "id", "fullName", "amount", "status", "transactionId"]);

  const columnsConfig = [
    { title: "Sr No", dataIndex: "srNo", key: "srNo", render: (_, __, index) => index + 1 },
    { title: "ID", dataIndex: "id", key: "id", sorter: true },
    { title: "Full Name", dataIndex: "fullName", key: "fullName", sorter: true },
    { title: "Amount", dataIndex: "amount", key: "amount", sorter: (a, b) => a.amount - b.amount },
    { title: "Status", dataIndex: "status", key: "status", filters: [
        { text: 'Success', value: 'Success' },
        { text: 'Failed', value: 'Failed' },
        { text: 'Pending', value: 'Pending' },
      ],
      onFilter: (value, record) => record.status.toLowerCase() === value.toLowerCase() 
    },
    { title: "Transaction ID", dataIndex: "transactionId", key: "transactionId" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Mobile", dataIndex: "mobile", key: "mobile" },
    { title: "Created At", dataIndex: "createdAt", key: "createdAt", sorter: true }
  ];

  const filteredColumns = columnsConfig.filter(col => selectedColumns.includes(col.dataIndex));

  const fetchPayments = debounce(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/api/payments", {
        params: { page: page - 1, size: pageSize, search, status: statusFilter }
      });
      const paymentsWithSrNo = response.data.content.map((payment, index) => ({
        ...payment,
        srNo: index + 1 + (page - 1) * pageSize
      }));
      setPayments(paymentsWithSrNo);
      setTotal(response.data.totalElements);
    } catch (error) {
      message.error("Error fetching payments.");
    }
    setLoading(false);
  }, 500);

  useEffect(() => {
    fetchPayments();
  }, [page, pageSize, search, statusFilter]);

  const handleExport = (type) => {
    if (type === 'csv') {
      const csvContent = [
        ["Sr No", "ID", "Full Name", "Amount", "Status", "Transaction ID", "Email", "Mobile", "Created At"],
        ...payments.map(p => [p.srNo, p.id, p.fullName, p.amount, p.status, p.transactionId, p.email, p.mobile, p.createdAt])
      ].map(e => e.join(",")).join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'payments_report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (type === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(payments);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");
      XLSX.writeFile(workbook, "payments_report.xlsx");
    } else if (type === 'pdf') {
      const doc = new jsPDF();
      const tableColumn = ["Sr No", "ID", "Full Name", "Amount", "Status", "Transaction ID", "Email", "Mobile", "Created At"];
      const tableRows = payments.map(payment => [
        payment.srNo, payment.id, payment.fullName, payment.amount, payment.status, payment.transactionId, payment.email, payment.mobile, payment.createdAt
      ]);
      autoTable(doc, { head: [tableColumn], body: tableRows });
      doc.save("payments_report.pdf");
    }
  };

  const exportMenu = (
    <Menu>
      <Menu.Item key="csv" onClick={() => handleExport('csv')}>Export as CSV</Menu.Item>
      <Menu.Item key="excel" onClick={() => handleExport('excel')}>Export as Excel</Menu.Item>
      <Menu.Item key="pdf" onClick={() => handleExport('pdf')}>Export as PDF</Menu.Item>
    </Menu>
  );

  const handleColumnChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };

  return (
    <div className="payment-container">
      <div className="header-container">
        <Input
          placeholder="Search by Name or Email"
          prefix={<SearchOutlined />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Select
          placeholder="Filter by Status"
          value={statusFilter}
          onChange={(value) => setStatusFilter(value)}
          allowClear
        >
          <Option value="Success">Success</Option>
          <Option value="Failed">Failed</Option>
          <Option value="Pending">Pending</Option>
        </Select>
        <Dropdown overlay={exportMenu} placement="bottomRight">
          <Button icon={<DownloadOutlined />}>Export Report</Button>
        </Dropdown>
      </div>
      <div className="column-selector">
        <Checkbox.Group
          options={columnsConfig.map(col => ({ label: col.title, value: col.dataIndex }))}
          value={selectedColumns}
          onChange={handleColumnChange}
        />
      </div>
      <Table
        columns={filteredColumns}
        dataSource={payments}
        loading={loading}
        pagination={{
          current: page,
          total: total,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (newPage, newSize) => {
            setPage(newPage);
            setPageSize(newSize);
          }
        }}
        rowKey="id"
      />
    </div>
  );
};

export default PaymentDetails;
