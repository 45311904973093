import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./UserProfile.css";

const UserProfile = ({ loggedInEmail }) => {
  const [userDetails, setUserDetails] = useState({});
  const [email, setEmail] = useState(loggedInEmail || "");
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (email) {
      fetchUserDetails();
    }
  }, [email]);

  // Fetch user details by email
  const fetchUserDetails = async () => {
    if (!email) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axiosInstance.get("/api/users/details-by-email", {
        params: { email },
      });
      setUserDetails(response.data);
      setIsEditing(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to fetch user details."
      );
    }
  };

  // Update user profile
  const updateUserProfile = async () => {
    if (!userDetails.firstName || !userDetails.lastName) {
      toast.error("First Name and Last Name cannot be empty.");
      return;
    }

    try {
      const { id, firstName, lastName, contactNumber } = userDetails;
      await axiosInstance.put(`/api/users/${id}/update-profile`, {
        firstName,
        lastName,
        contactNumber,
      });
      toast.success("User profile updated successfully.");
      setIsEditing(false);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to update user profile."
      );
    }
  };

  return (
    <div className="user-profile">
      <ToastContainer />
      <div className="card">
        <div className="card-header">
          <h1>User Profile</h1>
        </div>

        {/* User Details */}
        {userDetails.id ? (
          <div className="card-body">
            <div className="detail-item">
              <label>First Name:</label>
              {isEditing ? (
                <input
                  type="text"
                  value={userDetails.firstName}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, firstName: e.target.value })
                  }
                />
              ) : (
                <span>{userDetails.firstName}</span>
              )}
            </div>
            <div className="detail-item">
              <label>Last Name:</label>
              {isEditing ? (
                <input
                  type="text"
                  value={userDetails.lastName}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, lastName: e.target.value })
                  }
                />
              ) : (
                <span>{userDetails.lastName}</span>
              )}
            </div>
            <div className="detail-item">
              <label>Contact Number:</label>
              {isEditing ? (
                <input
                  type="tel"
                  value={userDetails.contactNumber}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      contactNumber: e.target.value,
                    })
                  }
                />
              ) : (
                <span>{userDetails.contactNumber || "N/A"}</span>
              )}
            </div>
            <div className="detail-item">
              <label>Email:</label>
              <span>{userDetails.email}</span>
            </div>
            <div className="detail-item">
              <label>Role:</label>
              <span>{userDetails.roleName}</span>
            </div>
            <div className="detail-item">
              <label>Status:</label>
              <span>{userDetails.active ? "Active" : "Inactive"}</span>
            </div>

            {isEditing ? (
              <div className="profile-actions">
                <button onClick={updateUserProfile} className="save-button">
                  Save
                </button>
                <button
                  onClick={() => setIsEditing(false)}
                  className="cancel-button"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button onClick={() => setIsEditing(true)} className="edit-button">
                Edit Profile
              </button>
            )}
          </div>
        ) : (
          <div className="no-details">
            <p>No user details available. Please search or log in.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;