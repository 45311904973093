import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactQuill from "react-quill"; // ✅ Import ReactQuill for rich text editor
import "react-quill/dist/quill.snow.css"; // ✅ Import Quill styles
import "./NewsFeed.css";

const NewsFeed = ({ newsItems, onLoadMore, loading, hasMore }) => {
  const containerRef = useRef(null);
  const observerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  // ✅ Auto-scroll effect (Fixed)
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const startAutoScroll = () => {
      if (!isHovered) {
        const interval = setInterval(() => {
          if (container.scrollTop + container.clientHeight < container.scrollHeight) {
            container.scrollTop += 1; // ✅ Adjust for smooth scrolling
          } else {
            clearInterval(interval);
          }
        }, 50);
        return interval;
      }
    };

    const scrollInterval = startAutoScroll();
    return () => clearInterval(scrollInterval);
  }, [isHovered, newsItems]);

  // ✅ Intersection Observer for Infinite Scroll
  const lastNewsItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            console.log("Fetching more news...");
            onLoadMore();
          }
        },
        {
          root: null, // ✅ Observe relative to viewport
          rootMargin: "20px", // ✅ Load more before reaching the bottom
          threshold: 1.0, // ✅ Ensures proper detection
        }
      );

      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore, onLoadMore]
  );

  return (
    <div className="news-feed-wrapper">
      <div
        className="news-feed-container"
        ref={containerRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {newsItems.map((news, index) => (
          <div
            key={news.id}
            className="news-item"
            ref={index === newsItems.length - 1 ? lastNewsItemRef : null} // ✅ Attach observer to last item
          >
            <h4 className="news-title">{news.title}</h4>
            {/* ✅ ReactQuill to display formatted news description */}
            <ReactQuill value={news.description} readOnly={true} theme="bubble" />
            <small className="news-date">{new Date(news.createdDate).toLocaleString()}</small>
          </div>
        ))}
      </div>
      {loading && <p className="loading-text">Loading more news...</p>}
    </div>
  );
};

export default NewsFeed;
