import React, { useState } from "react";
import Navbar from "../../include/Navbar";
import Footer from "../../include/Footer";
import JobList from "./JobList";
import SearchBar from "./SearchBar";
import FilterPanel from "./FilterPanel";
import "./Careers.css";

const Careers = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    remote: false,
    fullTime: false,
  });

  const handleViewOpenings = () => {
    const openingsSection = document.getElementById("openings");
    if (openingsSection) {
      openingsSection.scrollIntoView({ behavior: "smooth" });
    } else {
      console.error("Openings section not found!");
    }
  };

  return (
    <div className="careers-page">
      {/* Navbar */}
      <Navbar />

      {/* Banner Section */}
      <div className="banner">
        <div className="banner-overlay">
          <h1>Join Us and Build the Future of Technology</h1>
          <p>
            At Encoders Software, we believe in the power of innovation,
            collaboration, and passion. As a growing leader in IT services, we
            provide a dynamic environment where ideas flourish, careers thrive,
            and every contribution makes a meaningful impact.
          </p>
          <button className="view-openings-btn" onClick={handleViewOpenings}>
            View Openings
          </button>
        </div>
      </div>

      {/* About Us / Additional Info Section */}
      <section className="about-us">
        <h2>Why Work at Encoders Software?</h2>
        <p>
          We’re not just a company; we’re a collaborative community of forward-thinkers,
          developers, designers, and strategists. Our culture is built on teamwork,
          personal growth, and the relentless pursuit of excellence.
        </p>
        <ul>
          <li>Competitive Salary & Comprehensive Benefits</li>
          <li>Flexible Work Environment (Remote-Friendly)</li>
          <li>Personal & Professional Development Programs</li>
          <li>Inclusive and Diverse Workforce</li>
          <li>Opportunities to Innovate and Create Impact</li>
        </ul>
      </section>

      {/* Search & Job Listings */}
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <div id="openings" className="content-row">
        <FilterPanel filters={filters} setFilters={setFilters} />
        <JobList searchQuery={searchQuery} filters={filters} />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Careers;
