import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance"; // or import axios from "axios"
import TeamMember from "./TeamMember";
import "./TeamSection.css";

const TeamSection = () => {
  // State to store grouped members
  const [groupedMembers, setGroupedMembers] = useState([]);
  // State to track whether data is still loading
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        // Start loading
        setIsLoading(true);

        const response = await axiosInstance.get("/api/team-members/getAllTeamMembers");
        const members = response.data || [];

        // Separate into three groups
        const ceoFounder = [];
        const directorManager = [];
        const others = [];

        // Group each member by their role (ignoring case/spelling)
        members.forEach((m) => {
          const roleLower = (m.role || "").toLowerCase();

          if (roleLower.includes("ceo") || roleLower.includes("founder")) {
            ceoFounder.push(m);
          } else if (roleLower.includes("director") || roleLower.includes("manager")) {
            directorManager.push(m);
          } else {
            others.push(m);
          }
        });

        // Build an ordered array of groups for rendering
        const finalGroups = [
          { roleHeading: "CEO / Founder", members: ceoFounder },
          { roleHeading: "Director / Manager", members: directorManager },
          { roleHeading: "Others", members: others },
        ];

        setGroupedMembers(finalGroups);
      } catch (error) {
        console.error("Error fetching team members:", error);
      } finally {
        // Once data is fetched (success or error), stop loading
        setIsLoading(false);
      }
    };

    fetchMembers();
  }, []);

  return (
    <section className="team">
      <div className="team-content-container">
        <h2 className="section-title">Meet Our Team</h2>
        <p className="section-subtitle">
          Our dedicated team of professionals is committed to delivering
          exceptional results and driving innovation.
        </p>

        {/* GLOBAL LOADER (Spinner) */}
        {isLoading ? (
          <div className="global-loader-container">
            <div className="spinner"></div>
            <p>Loading team members...</p>
          </div>
        ) : (
          <>
            {groupedMembers.map((group, groupIndex) => {
              // Hide the entire group (title + members) if there are no members
              if (!group.members || group.members.length === 0) {
                return null;
              }

              return (
                <div key={group.roleHeading}>
                  {/* Heading for each group */}
                  <h3 className="role-heading">{group.roleHeading}</h3>

                  {/* Render each member in this group */}
                  <div className="team-members">
                    {group.members.map((member, index) => (
                      <TeamMember
                        key={member.id}
                        member={member}
                        // Just an example offset for animations
                        delay={groupIndex * 100 + index * 50}
                      />
                    ))}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </section>
  );
};

export default TeamSection;
