import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "./axiosInstance";
import "./NewsScroller.css";

export default function NewsScroller({ speed = 1 }) {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);
  const animationFrameRef = useRef(null);
  const isHoveredRef = useRef(false);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axiosInstance.get("/api/course-news", {
          params: { page: 0, size: 10 },
        });
        if (response.data?.content?.length > 0) {
          // Duplicate news for seamless infinite scrolling
          setNewsList([...response.data.content, ...response.data.content]);
        }
      } catch (error) {
        console.error("Failed to fetch news:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // Auto-scroll function using requestAnimationFrame
  const scrollNews = () => {
    if (!scrollRef.current || isHoveredRef.current) {
      animationFrameRef.current = requestAnimationFrame(scrollNews);
      return;
    }
    const container = scrollRef.current;
    container.scrollTop += speed;

    // Reset scroll when reaching half the scrollHeight (due to duplication)
    if (container.scrollTop >= container.scrollHeight / 2) {
      container.scrollTop = 0;
    }
    animationFrameRef.current = requestAnimationFrame(scrollNews);
  };

  const startScrolling = () => {
    isHoveredRef.current = false;
    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(scrollNews);
  };

  const stopScrolling = () => {
    isHoveredRef.current = true;
  };

  useEffect(() => {
    if (newsList.length > 0) {
      startScrolling();
    }
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [newsList]);

  return (
    <div className="news-section">
      <h2 className="news-title">📌 Latest Course News</h2>
      {loading ? (
        <p className="loading-text">Loading news...</p>
      ) : (
        <div
          className="news-container"
          ref={scrollRef}
          onMouseEnter={stopScrolling}
          onMouseLeave={startScrolling}
        >
          <div className="news-list">
            {newsList.map((news, index) => (
              <div key={index} className="news-item">
                <h3
                  className={`news-heading ${
                    index % 2 === 0 ? "red-title" : "blue-title"
                  }`}
                >
                  {news.title}
                </h3>
                <p className="news-content">{news.content}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
