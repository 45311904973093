// Navbar.jsx
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setIsLoggedIn(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar bg-white py-2 shadow-sm">
        <div className="container d-flex justify-content-between align-items-center">
          {/* Contact Info */}
          <div className="contact-info d-flex align-items-center">
            <div className="me-4 d-flex align-items-center">
              <i className="fa fa-phone me-2" style={{ color: "blue" }}></i>
              <a
                href="tel:+917258803502"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                +91-7258803502
              </a>
            </div>
            <div className="d-flex align-items-center">
              <i className="fa fa-envelope me-2" style={{ color: "blue" }}></i>
              <a
                href="mailto:support@encoderssoftware.com"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                support@encoderssoftware.com
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="/logo.png" alt="Encoders Software Pvt Ltd" height="50" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              {/* Home */}
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>

              {/* About Us (Dropdown) */}
              <li className="nav-item dropdown">
                <Link
                  to="/about"
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </Link>

                <ul className="dropdown-menu">
                  {/* Link to sections via hash anchors */}
                  <li>
                    <Link to="/about#company-overview" className="dropdown-item">
                      Company Overview
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#about-company" className="dropdown-item">
                      About Company
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#services" className="dropdown-item">
                      Our Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#dashboard" className="dropdown-item">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#project-delivery" className="dropdown-item">
                      Project Delivery
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#team" className="dropdown-item">
                      Our Team
                    </Link>
                  </li>
                  <li>
                    <Link to="/about#banner" className="dropdown-item">
                      Banner / Contact
                    </Link>
                  </li>
                </ul>
              </li>

              {/* Contact Us */}
              <li className="nav-item">
                <Link to="/contact" className="nav-link">
                  Contact Us
                </Link>
              </li>

              {/* Careers */}
              <li className="nav-item">
                <Link to="/careers" className="nav-link">
                  Careers
                </Link>
              </li>

              {/* Blogs */}
              <li className="nav-item">
                <Link to="/BlogsUsers" className="nav-link">
                  Blogs
                </Link>
              </li>

              {/* Training Program */}
              <li className="nav-item">
                <Link to="/training-course" className="nav-link">
                  Training Program
                </Link>
              </li>

                 

              {/* If logged in, show 'Menu' */}
              {isLoggedIn && (
                <li className="nav-item">
                  <Link to="/HomePage" className="nav-link">
                    Menu
                  </Link>
                </li>
              )}

              {/* Login / Logout Button */}
              <li className="nav-item ms-3">
                {isLoggedIn ? (
                  <button
                    onClick={handleLogout}
                    className="btn btn-outline-danger"
                  >
                    <i className="fa fa-sign-out me-2"></i> Logout
                  </button>
                ) : (
                  <Link to="/LoginSignup" className="btn btn-primary">
                    <i className="fa fa-user me-2"></i> Login
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
