import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { FaEdit, FaTrash, FaPlus, FaSearch } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./JobServices.css";

const JobServices = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [formData, setFormData] = useState({
    id: null,
    title: "",
    location: "",
    jobType: "",
    description: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [validationError, setValidationError] = useState("");

  // Fetch jobs from the API
  const fetchJobs = async () => {
    try {
      const response = await axiosInstance.get("/api/jobs", {
        params: {
          keyword: searchTerm,
          page: currentPage - 1,
          size: pageSize,
        },
      });
      const { content, totalPages } = response.data;
      setJobs(content || []);
      setTotalPages(totalPages || 1);
    } catch (error) {
      console.error("Error fetching jobs:", error.response?.data || error.message);
      toast.error("Failed to load jobs.");
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [currentPage, pageSize]);

  // Handle input changes in form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Add or edit job
  const handleAddEditJob = async () => {
    if (!formData.title || !formData.location || !formData.jobType || !formData.description) {
      setValidationError("All fields, including Job Type, are required.");
      toast.error("All fields, including Job Type, are required.");
      return;
    }

    if (formData.jobType === "") {
      setValidationError("Please select a valid Job Type.");
      toast.error("Please select a valid Job Type.");
      return;
    }

    try {
      if (isEditing) {
        await axiosInstance.put(`/api/jobs/${formData.id}`, formData);
        toast.success("Job updated successfully!");
      } else {
        await axiosInstance.post("/api/jobs", formData);
        toast.success("Job added successfully!");
      }
      resetForm();
      fetchJobs();
    } catch (error) {
      console.error("Error saving job:", error.response?.data || error.message);
      toast.error(error.response?.data?.message || "Failed to save job.");
    }
  };

  // Delete job
  const handleDeleteJob = async (id) => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      try {
        await axiosInstance.delete(`/api/jobs/${id}`);
        toast.success("Job deleted successfully!");
        fetchJobs();
      } catch (error) {
        console.error("Error deleting job:", error.response?.data || error.message);
        toast.error("Failed to delete job.");
      }
    }
  };

  // Edit button click
  const handleEditClick = (job) => {
    setFormData(job);
    setValidationError("");
    setIsEditing(true);
    setShowForm(true);
  };

  // Reset the form
  const resetForm = () => {
    setFormData({ id: null, title: "", location: "", jobType: "", description: "" });
    setValidationError("");
    setIsEditing(false);
    setShowForm(false);
  };

  // Search
  const handleSearch = () => {
    setCurrentPage(1);
    fetchJobs();
  };

  return (
    <div className="job-services-container">
      <ToastContainer />
      <h1>Manage Jobs</h1>

      {/* Actions */}
      <div className="actions">
        <div className="search-container">
          <input
            type="text"
            placeholder="Search jobs..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button onClick={handleSearch} className="search-button">
            <FaSearch />
          </button>
        </div>
        <div className="page-size-selector">
          <label htmlFor="pageSize" className="page-size-label">
            Items per page:
          </label>
          <select
            id="pageSize"
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            className="page-size-dropdown"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <button onClick={() => setShowForm(true)} className="add-button">
          <FaPlus /> Add Job
        </button>
      </div>

      {/* Add/Edit Form */}
      {showForm && (
        <div className="form-container">
          <input
            type="text"
            name="title"
            placeholder="Job Title"
            value={formData.title}
            onChange={handleInputChange}
          />
          <input
            type="text"
            name="location"
            placeholder="Location"
            value={formData.location}
            onChange={handleInputChange}
          />
          <select name="jobType" value={formData.jobType} onChange={handleInputChange}>
            <option value="">Select Job Type</option>
            <option value="Remote">Remote Jobs</option>
            <option value="FullTime">Full-Time</option>
            <option value="PartTime">Part-Time</option>
            <option value="Internship">Internship</option>
          </select>
          <textarea
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleInputChange}
          ></textarea>
          {validationError && <p className="validation-error">{validationError}</p>}
          <div className="form-buttons-row">
            <button onClick={handleAddEditJob} className="form-submit-button">
              {isEditing ? "Update Job" : "Add Job"}
            </button>
            <button onClick={resetForm} className="cancel-button">
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Jobs Table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Location</th>
              <th>Job Type</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs.length > 0 ? (
              jobs.map((job) => (
                <tr key={job.id}>
                  <td>{job.title}</td>
                  <td>{job.location}</td>
                  <td>{job.jobType}</td>
                  <td>{job.description}</td>
                  <td>
                    <button onClick={() => handleEditClick(job)} className="edit-button">
                      <FaEdit /> Edit
                    </button>
                    <button onClick={() => handleDeleteJob(job.id)} className="delete-button">
                      <FaTrash /> Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No jobs available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="pagination">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default JobServices;
