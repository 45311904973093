import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./DiscountGenerator.css";

export default function DiscountGenerator() {
  const [amount, setAmount] = useState("");
  const [expiryDays, setExpiryDays] = useState("");
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [courses, setCourses] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCourses();
    fetchDiscounts();
  }, []);

  // 🔥 Fetch available courses
  const fetchCourses = async () => {
    try {
      const response = await axiosInstance.get("/api/discounts/courses");
      setCourses(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      toast.error("Failed to load courses.");
      setCourses([]);
    }
  };

  // 🔥 Fetch existing discount codes
  const fetchDiscounts = async () => {
    try {
      const response = await axiosInstance.get("/api/discounts");
      setDiscounts(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      toast.error("Failed to fetch discount codes.");
      setDiscounts([]);
    }
  };

  // 🔥 Generate a new discount and refresh the list
  const generateDiscount = async () => {
    if (!amount || !expiryDays || !selectedCourseId) {
      toast.error("Please enter amount, expiry days, and select a course!");
      return;
    }

    try {
      setLoading(true);
      const response = await axiosInstance.post(`/api/discounts/generate`, null, {
        params: {
          amount: parseFloat(amount),
          expiryDays: parseInt(expiryDays, 10),
          courseId: Number(selectedCourseId),
        },
      });

      toast.success("Discount Code Generated Successfully!");

      // ✅ Ensure UI updates instantly by fetching the latest discounts
      fetchDiscounts();

      // ✅ Clear input fields
      setAmount("");
      setExpiryDays("");
      setSelectedCourseId("");
    } catch (error) {
      toast.error(error.response?.data?.error || "Failed to generate discount code.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="discount-generator-container">
      <h2>Generate Discount Code</h2>

      <div className="discount-form">
        {/* 🔹 Course Selection Dropdown */}
        <select value={selectedCourseId} onChange={(e) => setSelectedCourseId(e.target.value)}>
          <option value="">Select a Course</option>
          {courses.length > 0 ? (
            courses.map((course) => (
              <option key={course.id} value={course.id}>
                {course.title} (₹{course.originalPrice})
              </option>
            ))
          ) : (
            <option disabled>No courses available</option>
          )}
        </select>

        <input
          type="number"
          placeholder="Enter Discount Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <input
          type="number"
          placeholder="Enter Expiry Days"
          value={expiryDays}
          onChange={(e) => setExpiryDays(e.target.value)}
        />
        <button className="generate-btn" onClick={generateDiscount} disabled={loading}>
          {loading ? "Generating..." : "Generate Code"}
        </button>
      </div>

      {/* 🔹 List of Existing Discounts */}
      <h3>Existing Discount Codes</h3>
      <table className="discount-table">
        <thead>
          <tr>
            <th>Code</th>
            <th>Amount</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>Course</th>
          </tr>
        </thead>
        <tbody>
          {discounts.length > 0 ? (
            discounts.map((discount) => (
              <tr key={discount.id}>
                <td>{discount.discountCode}</td>
                <td>₹{discount.discountAmount}</td>
                <td>{new Date(discount.expiryDate).toLocaleDateString()}</td>
                <td>{discount.active ? "Active" : "Expired"}</td>
                <td>{discount.course?.title || "N/A"}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No discount codes available.</td>
            </tr>
          )}
        </tbody>
      </table>

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}
