import React, { useState } from "react";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";
import { FaSearchPlus, FaLinkedin, FaFacebookSquare } from "react-icons/fa";

const TeamMember = ({ member, delay }) => {
  const {
    name,
    role,
    qualification,
    linkedinUrl,
    facebookUrl,
    image,
    imageContentType
  } = member;

  // State for image lightbox
  const [isOpen, setIsOpen] = useState(false);

  // State for tracking if the image is still loading
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Construct base64 image source if available
  const imageSrc = image ? `data:${imageContentType};base64,${image}` : null;

  return (
    <div className="team-member" data-aos="fade-up" data-aos-delay={delay}>
      <div
        className="team-image-container"
        style={{ cursor: imageSrc ? "zoom-in" : "default" }}
        onClick={() => {
          if (imageSrc) {
            setIsOpen(true);
          }
        }}
      >
        {imageSrc && (
          <>
            {/* Show the image */}
            <img
              src={imageSrc}
              alt={name}
              className="team-image"
              onLoad={() => setIsImageLoading(false)}
            />

            {/* If image is still loading, show loader overlay */}
            {isImageLoading && (
              <div className="image-loader-overlay">
                <div className="image-spinner"></div>
              </div>
            )}

            {/* Magnifying glass icon in the corner */}
            <div className="zoom-icon">
              <FaSearchPlus />
            </div>
          </>
        )}
      </div>

      <div className="team-info">
        <h4 className="team-name">{name}</h4>
        <p className="team-role">{role}</p>
        <p className="team-qualification">{qualification}</p>

        <div className="social-links">
          {linkedinUrl && linkedinUrl.trim() !== "" && (
            <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          )}
          {facebookUrl && facebookUrl.trim() !== "" && (
            <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
              <FaFacebookSquare />
            </a>
          )}
        </div>
      </div>

      {/* Lightbox for Zoom */}
      {isOpen && (
        <Lightbox
          mainSrc={imageSrc}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom={true}
        />
      )}
    </div>
  );
};

export default TeamMember;
