// About.jsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import Dashboard from "./Dashboard";
import HeroSection from "./HeroSection";
import AboutCompany from "./AboutCompany";
import ServicesSection from "./ServicesSection";
import ProjectDelivery from "./ProjectDelivery";
import TeamSection from "./TeamSection";
import BannerSection from "./BannerSection";
import config from "../../include/config";
import axios from "axios";

import "./About.css";

const About = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [teamMembers, setTeamMembers] = useState([]);
  const [error, setError] = useState(null);

  // Handle "Contact" button in BannerSection (unchanged)
  const handleNavigate = () => {
    navigate("/contact");
  };

  // 1) Scroll to the hash section whenever location changes
  useEffect(() => {
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Optionally scroll to top if no hash
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  // 2) Fetch team members (unchanged)
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await axios.get(
          `${config.BASE_URL}/api/team-members/getAllTeamMembers`
        );
        setTeamMembers(response.data);
      } catch (err) {
        console.error("Error fetching team members:", err);
        setError("Failed to fetch team members. Please try again later.");
      }
    };

    fetchTeamMembers();
  }, []);

  return (
    <div>
      {/* Optionally remove <Navbar /> if you already show it in App.js */}
      <Navbar />

      {/* Section: Hero */}
      <section id="hero">
        <HeroSection />
      </section>

      {/* Section: Company Overview */}
      <section className="company-overview" id="company-overview">
        <h2>About Encoders Software</h2>
        <p>
    Encoders Software Private Limited is a leading IT solutions provider, founded in <strong>2019</strong> and headquartered in <strong>Ranchi, Jharkhand, India</strong>.
    Known for innovation, excellence, and customer-first solutions, we are committed to delivering cutting-edge technology services across industries.
  </p>
        <ul>
          <li><strong>Founded:</strong> 2019</li>
          <li><strong>CIN:</strong> U74999JH2019PTC012902</li>
        </ul>
        <p>
    With a focus on <strong>customer satisfaction</strong>, <strong>innovation</strong>, and <strong>integrity</strong>, Encoders Software continues to empower businesses to thrive in the digital era.
  </p>
      </section>

      {/* Section: About Company */}
      <section id="about-company">
        <AboutCompany />
      </section>

      {/* Section: Services */}
      <section id="services">
        <ServicesSection />
      </section>

      {/* Section: Dashboard */}
      <section id="dashboard">
        <Dashboard />
      </section>

      {/* Section: Project Delivery */}
      <section id="project-delivery">
        <ProjectDelivery />
      </section>

      {/* Section: Team */}
      <section id="team">
        <TeamSection teamMembers={teamMembers} />
      </section>

      {/* Section: Banner */}
      <section id="banner">
        <BannerSection handleNavigate={handleNavigate} />
      </section>

      <Footer />
    </div>
  );
};

export default About;
