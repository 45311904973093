import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faPaperPlane,
  faRobot,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import Footer from "../../include/Footer";
import axiosInstance from "./axiosInstance";
import config from "../../include/config"; // Your server config for captcha & contact form
import Navbar from "../../include/Navbar";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const location = useLocation();

  // ============= Contact Form State ============
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    captchaInput: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ============= ChatGPT Chatbot State ============
  const [chatMessages, setChatMessages] = useState([
    {
      sender: "bot",
      text: "👋 Hello! I’m your Encoders Software assistant. How can I help you today?",
    },
  ]);
  const [chatInput, setChatInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  // Show chatbot window toggle + auto-open control
  const [showChat, setShowChat] = useState(false);
  // Tracks if the chat has been auto-opened or manually toggled
  const [chatInitialized, setChatInitialized] = useState(false);

  // Scroll to contact form after a slight delay
  useEffect(() => {
    setTimeout(() => {
      const contactForm = document.querySelector(".contact-form");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 500);
  }, [location]);

  // Auto-open the chat after 5 seconds (if user hasn't toggled it already)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!chatInitialized) {
        openChatWithSound();
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [chatInitialized]);

  // ======== Function to open chat & play notification sound =========
  const openChatWithSound = () => {
    setShowChat(true);
    setChatInitialized(true); // Mark chat as initialized
    // Play a short notification sound (replace path with your own)
    const sound = new Audio("/sounds/chat-open.mp3");
    sound.play().catch((err) => {
      console.error("Error playing sound:", err);
    });
  };

  // ============= Fetch Captcha on mount =============
  useEffect(() => {
    fetchCaptcha();
  }, []);

  // ============= Fetch Captcha =============
  const fetchCaptcha = async () => {
    try {
      const response = await axiosInstance.get(`/api/captcha/generate`);
      setCaptcha(response.data);
    } catch (error) {
      console.error("Error fetching captcha:", error);
    }
  };

  // ============= Contact Form Handlers =============
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required.";
    if (!formData.email.trim()) newErrors.email = "Email is required.";
    if (!formData.mobile.trim()) newErrors.mobile = "Mobile number is required.";
    if (!formData.message.trim()) newErrors.message = "Message is required.";

    // Simple email format check
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Captcha check
    if (!formData.captchaInput.trim()) {
      newErrors.captchaInput = "Captcha is required.";
    } else if (
      formData.captchaInput.trim().toLowerCase() !== captcha.trim().toLowerCase()
    ) {
      newErrors.captchaInput = "Captcha does not match.";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsSubmitting(true);

    try {
      // Send contact form data to your backend
      const response = await axiosInstance.post(
        `/api/contact/sendMessage`,
        formData
      );
      setResponseMessage(response.data);
      // Reset form
      setFormData({
        name: "",
        email: "",
        mobile: "",
        company: "",
        message: "",
        captchaInput: "",
      });
      setErrors({});
      fetchCaptcha();
    } catch (error) {
      console.error("Error sending message:", error);
      setResponseMessage("Failed to send message. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // ============= ChatGPT Chatbot Handlers =============
  const toggleChat = () => {
    setShowChat((prev) => !prev);
    setChatInitialized(true); // Mark as initialized if user manually toggles
  };

  const handleChatSend = async () => {
    if (!chatInput.trim() || isTyping) return;
    setIsTyping(true);

    const userMessage = { sender: "user", text: chatInput.trim() };
    setChatMessages((prev) => [...prev, userMessage]);
    setChatInput("");

    try {
      const openAiMessages = chatMessages.map((msg) =>
        msg.sender === "bot"
          ? { role: "assistant", content: msg.text }
          : { role: "user", content: msg.text }
      );
      openAiMessages.push({ role: "user", content: userMessage.text });

      const response = await axiosInstance.post("/api/chat", {
        messages: openAiMessages,
      });

      const data = response.data;
      if (data?.choices?.[0]?.message?.content) {
        const botText = data.choices[0].message.content.trim();
        setChatMessages((prev) => [...prev, { sender: "bot", text: botText }]);
      } else if (data?.error?.message) {
        setChatMessages((prev) => [
          ...prev,
          { sender: "bot", text: `Error: ${data.error.message}` },
        ]);
      } else {
        setChatMessages((prev) => [
          ...prev,
          { sender: "bot", text: "Sorry, I couldn't understand that." },
        ]);
      }
    } catch (error) {
      console.error("Chat error:", error);
      setChatMessages((prev) => [
        ...prev,
        {
          sender: "bot",
          text:
            error?.response?.data?.error?.message ||
            "Oops! Something went wrong. Please try again later.",
        },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleChatKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleChatSend();
    }
  };

  return (
    <>
      <Navbar />

      <div className="contact-us">
        {/* Banner Section */}
        <div className="banner">
          <img
            src="/images/big-images/banner_contact.jpg"
            alt="Contact Banner"
            className="banner-image"
          />
          <div className="banner-overlay">
            <h1>Contact Us</h1>
            <p>We’re here to help. Reach out with any queries or feedback.</p>
          </div>
        </div>

        {/* Main Section */}
        <div className="contact-container">
          {/* Company Details */}
          <div className="contact-details">
            <h2>Company Details</h2>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
              &nbsp; Oak Forest Flat No 8 F, Pundag Road, Ranchi, Jharkhand
              834002
            </p>
            <p>
              <FontAwesomeIcon icon={faPhone} className="icon" />
              &nbsp;
              <a href="tel:+917258803502" className="clickable">
                +91 7258803502
              </a>{" "}
              ,{" "}
              <a href="tel:+919430796895" className="clickable">
                +91 9430796895
              </a>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              &nbsp;
              <a
                href="mailto:support@encoderssoftware.com"
                className="clickable"
              >
                support@encoderssoftware.com
              </a>
            </p>
            <div className="social-links">
              <a href="#" className="social-icon">
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a href="#" className="social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="#" className="social-icon">
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                href="https://www.instagram.com/encoders_software.pvt?igsh=MTQ2cTJwaXo1Ymo4dw=="
                className="social-icon"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://wa.me/919430796895"
                target="_blank"
                rel="noopener noreferrer"
                className="social-icon"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </div>
            <div className="map-container">
              <iframe
                title="Encoders Software Pvt. Ltd. Map"
                width="100%"
                height="250"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src="https://www.google.com/maps?q=Encoders+Software+Pvt+Ltd,Ranchi&output=embed"
              />
            </div>
          </div>

          {/* Contact Form */}
          <div className="contact-form">
            <h2>Send Us a Message</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your Name *"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p className="error-text">{errors.name}</p>}

              <input
                type="email"
                name="email"
                placeholder="Your Email Address *"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error-text">{errors.email}</p>}

              <input
                type="tel"
                name="mobile"
                placeholder="Phone Number *"
                value={formData.mobile}
                onChange={handleChange}
              />
              {errors.mobile && <p className="error-text">{errors.mobile}</p>}

              <textarea
                name="message"
                placeholder="Your Message *"
                rows="5"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <p className="error-text">{errors.message}</p>
              )}

              {/* Captcha */}
              <div className="captcha-box">
                <span className="captcha-text">{captcha}</span>
                <button type="button" onClick={fetchCaptcha}>
                  ↻
                </button>
              </div>
              <input
                type="text"
                name="captchaInput"
                placeholder="Enter Captcha *"
                value={formData.captchaInput}
                onChange={handleChange}
              />
              {errors.captchaInput && (
                <p className="error-text">{errors.captchaInput}</p>
              )}

              <button
                className="attractive-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Send Message"}
              </button>
            </form>
            {responseMessage && (
              <p className="response-message">{responseMessage}</p>
            )}
          </div>
        </div>
      </div>

      {/* Floating Chat Button (on the LEFT) */}
      <button className="floating-chat-button" onClick={toggleChat}>
        <FontAwesomeIcon icon={faRobot} />
      </button>

      {/* ChatGPT Chatbox */}
      {showChat && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <span>Chat with us</span>
            <button className="chatbot-close-btn" onClick={toggleChat}>
              ✕
            </button>
          </div>

          <div className="chatbot-window">
            {chatMessages.map((msg, idx) => (
              <div key={idx} className={`chatbot-message ${msg.sender}`}>
                <span className="chatbot-serial">{idx + 1}.</span>
                <div className="chatbot-avatar">
                  <FontAwesomeIcon
                    icon={msg.sender === "bot" ? faRobot : faUser}
                  />
                </div>
                <div className="chatbot-text">{msg.text}</div>
              </div>
            ))}
            {isTyping && (
              <div className="chatbot-typing">
                <FontAwesomeIcon icon={faRobot} /> Bot is typing...
              </div>
            )}
          </div>

          <div className="chatbot-input-container">
            <textarea
              className="chatbot-input"
              rows={2}
              placeholder="Type your message..."
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              onKeyDown={handleChatKeyPress}
            />
            <button onClick={handleChatSend} className="chatbot-send-btn">
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ContactUs;
